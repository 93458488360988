import React from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Page from '~layout/Page';
import Link from '~components/Link';
import ContentReportTable from '~src/components/admin/ContentReport/ContentReportTable';

const ContentReport = props => {
  const { pageContext } = props;
  const { timestamp, report } = pageContext;
  return (
    <Page hideBanner hideNavbar hideFooter>
      <Link to="/admin">
        <ArrowBackIcon /> Back to dashboard
      </Link>
      <ContentReportTable timestamp={timestamp} report={report} />
    </Page>
  );
};

ContentReport.propTypes = {
  pageContext: PropTypes.shape().isRequired
};

export default ContentReport;
