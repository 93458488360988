/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MaterialTable from 'material-table';
import SaveAlt from '@material-ui/icons/SaveAlt';

import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import linkResolver from '~prismic/utils/link-resolver';
import tableIcons from '~components/admin/tableIcons';
import Link from '~components/Link';

const playlistTitleRenderer = row => {
  const url = linkResolver({ uid: row.uid, type: 'playlist' });
  return (
    <p>
      <Link
        to={`https://aleph-beta.prismic.io/documents~k=playlist&b=working&c=published&l=en-us/${row.id}/`}
        target="_blank"
      >
        {row.title}
      </Link>
      <br />
      <br />
      <Link to={url} target="_blank">
        {url}
      </Link>
    </p>
  );
};

const playlistMaterialsRender = row =>
  row.materials && row.materials.length ? (
    <ol>
      {row.materials.map(material => (
        <li key={material.link}>
          <Link to={material.link}>
            {material.title} ({material.material_type})
          </Link>
        </li>
      ))}
    </ol>
  ) : null;

const videoTitleRenderer = row => {
  const url = linkResolver({ uid: row.uid, playlist_uid: row.playlist_uid, type: 'video' });
  return (
    <p>
      <Link
        to={`https://aleph-beta.prismic.io/documents~k=video&b=working&c=published&l=en-us/${row.id}/`}
        target="_blank"
      >
        {row.title}
      </Link>
      <br />
      <br />
      <Link to={url} target="_blank">
        {url}
      </Link>
    </p>
  );
};

const videoPlaylistTitleRenderer = row => {
  if (!row.playlist_id) {
    return <p>UNASSIGNED</p>;
  }
  return playlistTitleRenderer({
    uid: row.playlist_uid,
    id: row.playlist_id,
    title: row.playlist_title
  });
};

const renderValidationErrors = row => (
  <ol>
    {row.validationErrors
      ? row.validationErrors.map(message => <li key={message}>{message}</li>)
      : null}
  </ol>
);

const makeStringListRenderer = propertyName => row =>
  (
    <ol>
      {row[propertyName]
        ? row[propertyName].map(message => <li key={message}>{message}</li>)
        : null}
    </ol>
  );

const VideosTable = ({ videos }) => (
  <MaterialTable
    icons={tableIcons}
    title={null}
    columns={[
      { title: 'Title', field: 'title', render: videoTitleRenderer },
      { title: 'Playlist', field: 'playlist_title', render: videoPlaylistTitleRenderer },
      {
        title: 'Description',
        field: 'description'
      },
      { title: 'Tags', field: 'tags', render: makeStringListRenderer('tags') },
      {
        title: 'Search keywords',
        field: 'search_keywords'
      },
      { title: 'Topics', field: 'search_topic', render: makeStringListRenderer('search_topic') },
      {
        title: 'Holidays',
        field: 'search_holidays',
        render: makeStringListRenderer('search_holidays')
      },
      {
        title: 'Characters',
        field: 'search_characters',
        render: makeStringListRenderer('search_characters')
      },
      {
        title: 'Text References',
        field: 'search_text_references',
        render: makeStringListRenderer('search_text_references')
      },
      {
        title: 'Validation Errors',
        field: 'validationErrors',
        render: makeStringListRenderer('validationErrors')
      }
    ]}
    data={videos}
    options={{
      debounceInterval: 750,
      pageSize: 100,
      pageSizeOptions: [100, 1000],
      filtering: true
    }}
    actions={[
      {
        icon: SaveAlt,
        tooltip: 'Download raw CSV',
        isFreeAction: true,
        onClick: () => window.open('/content-report-videos.csv', '_blank')
      }
    ]}
  />
);
VideosTable.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      playlist_id: PropTypes.string,
      playlist_title: PropTypes.string,
      search_characters: PropTypes.arrayOf(PropTypes.string),
      search_keywords: PropTypes.string,
      search_parshat: PropTypes.arrayOf(PropTypes.string),
      search_text_references: PropTypes.arrayOf(PropTypes.string),
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      validationErrors: PropTypes.arrayOf(PropTypes.string),
      uid: PropTypes.string,
      search_topic: PropTypes.arrayOf(PropTypes.string)
    })
  )
};
VideosTable.defaultProps = {
  videos: []
};

// link_to_playlist	required_subscription_plan	author	materials	search_keywords	uid
const PlaylistsTable = ({ playlists }) => (
  <MaterialTable
    icons={tableIcons}
    title={null}
    columns={[
      { title: 'Title', field: 'title', render: playlistTitleRenderer },
      {
        title: 'Description',
        field: 'description'
      },
      { title: 'Required Subscription Plan', field: 'required_subscription_plan' },
      { title: 'Is New', field: 'label_is_new', lookup: { Yes: 'Yes', No: 'No', '': '' } },
      { title: 'Is Premium', field: 'label_is_premium', lookup: { Yes: 'Yes', No: 'No', '': '' } },
      {
        title: 'Is Producer',
        field: 'label_is_producer',
        lookup: { Yes: 'Yes', No: 'No', '': '' }
      },
      { title: 'Is Audio', field: 'label_is_audio', lookup: { Yes: 'Yes', No: 'No', '': '' } },
      {
        title: 'Author Name',
        field: 'author_name'
      },
      {
        title: 'Materials',
        field: 'materials',
        render: playlistMaterialsRender
      },
      {
        title: 'Search keywords',
        field: 'search_keywords'
      },
      {
        title: 'Tags',
        field: 'tags',
        render: makeStringListRenderer('tags')
      },
      { title: 'UID', field: 'uid' },
      { title: 'Validation Errors', field: 'validationErrors', render: renderValidationErrors }
    ]}
    data={playlists}
    options={{
      debounceInterval: 750,
      pageSize: 100,
      pageSizeOptions: [100, 1000],
      filtering: true
    }}
    actions={[
      {
        icon: SaveAlt,
        tooltip: 'Download raw CSV',
        isFreeAction: true,
        onClick: () => window.open('/content-report-playlists.csv', '_blank')
      }
    ]}
  />
);
PlaylistsTable.propTypes = {
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      uid: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      search_keywords: PropTypes.string,
      seo_canonical_url: PropTypes.string,
      description: PropTypes.string,
      seo_description: PropTypes.string,
      seo_keywords: PropTypes.string,
      seo_title: PropTypes.string,
      validationErrors: PropTypes.arrayOf(PropTypes.string)
    })
  )
};
PlaylistsTable.defaultProps = {
  playlists: []
};

const ContentReportTable = ({ timestamp, report }) => {
  const { playlists, videos, danglingVideos = [] } = report;
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const handleTabSwitch = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  return (
    <>
      <Typography variant="h4">Content report for {timestamp}</Typography>
      <Tabs
        value={currentTabIndex}
        onChange={handleTabSwitch}
        variant="scrollable"
        indicatorColor="primary"
      >
        <Tab label="Playlists" />
        <Tab label="Videos" />
      </Tabs>
      {currentTabIndex === 0 && <PlaylistsTable playlists={playlists} />}
      {currentTabIndex === 1 && <VideosTable videos={[...videos, ...danglingVideos]} />}
    </>
  );
};
ContentReportTable.propTypes = {
  timestamp: PropTypes.string.isRequired,
  report: PropTypes.shape({
    playlists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    danglingVideos: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

export default ContentReportTable;
